<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-progress-linear
      v-show="progressBar"
      indeterminate
      color="primary"
    />
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <base-material-card
      color="primary"
      icon="mdi-account-group"
      title="Listado de miembros"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="miembros"
        loading-text="Cargando... Espere por favor"
        disable-pagination
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-row
              align="center"
              class="list px-3 mx-auto"
            >
              <v-col
                cols="10"
                sm="8"
              >
                <v-text-field
                  v-model="search"
                  label="Buscar por Nombre, Apellido o E-mail"
                  hint="Si desea ver nuevamente la lista completa, presione el botón de Buscar, sin ningún texto en la caja."
                />
              </v-col>

              <v-col
                cols="2"
                sm="4"
              >
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  @click="page = 1; fetchMiembros();"
                >
                  <v-icon dark>
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-spacer />
            <v-dialog
              v-model="dialog"
              max-width="80%"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-show="isAdmin"
                  color="primary"
                  fab
                  dark
                  class="mb-2"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title
                  class="text-h3 red darken-3 white--text"
                  primary-title
                >
                  {{ formTitle }}
                </v-card-title>

                <v-card-text>
                  <v-row justify="center">
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <div
                        v-show="memberItem.token !== '' && memberItem.estado !== 'SUSCRITO' && memberItem.estado !== 'DADO DE BAJA'"
                        class="mb-3 font-weight-bold"
                      >
                        <span>URL personal del usuario: </span>
                        <a
                          :href="frontendUrl + 'member/register/' + memberItem.token"
                          target="_blank"
                        >
                          {{ frontendUrl }}member/register/{{ memberItem.token }}
                        </a>
                      </div>
                      <v-stepper
                        v-model="e1"
                        elevation="8"
                        non-linear
                      >
                        <v-stepper-header>
                          <v-stepper-step
                            :complete="e1 > 1"
                            step="1"
                            :rules="[() => !!stepValid1]"
                          >
                            Grupo BNI
                          </v-stepper-step>

                          <v-divider />

                          <v-stepper-step
                            :complete="e1 > 2"
                            step="2"
                            :rules="[() => !!stepValid2]"
                          >
                            Tus datos personales
                          </v-stepper-step>

                          <v-divider />

                          <v-stepper-step
                            :complete="e1 > 3"
                            step="3"
                            :rules="[() => !!stepValid3]"
                          >
                            Datos de facturación
                          </v-stepper-step>

                          <v-divider />

                          <v-stepper-step
                            :complete="e1 > 4"
                            step="4"
                            :rules="[() => !!stepValid4]"
                          >
                            Networking
                          </v-stepper-step>
                        </v-stepper-header>

                        <v-stepper-items>
                          <v-stepper-content step="1">
                            <v-card
                              class="mb-12"
                              elevation="2"
                              height="200px"
                              color="grey lighten-4"
                            >
                              <v-row>
                                <v-col
                                  class="mx-auto"
                                  cols="11"
                                  md="11"
                                >
                                  <v-form
                                    ref="stepForm1"
                                    v-model="stepValid1"
                                    lazy-validation
                                  >
                                    <v-select
                                      v-model="memberItem.grupo"
                                      class="mt-3"
                                      outlined
                                      :rules="campoRules"
                                      :items="grupoBNI"
                                      :readonly="showOption"
                                      item-text="grupo"
                                      item-value="id"
                                      label="GRUPO BNI"
                                      placeholder="GRUPO BNI"
                                    />
                                  </v-form>
                                </v-col>
                              </v-row>
                            </v-card>
                            <v-btn
                              @click="close"
                            >
                              Cancelar
                            </v-btn>
                            <v-btn
                              color="primary"
                              :disabled="!stepValid1"
                              @click="validate1"
                            >
                              Siguiente
                            </v-btn>
                          </v-stepper-content>

                          <v-stepper-content step="2">
                            <v-card
                              class="mb-12"
                              elevation="2"
                              color="grey lighten-4"
                            >
                              <v-row>
                                <v-col
                                  class="mx-auto"
                                  cols="11"
                                  md="11"
                                >
                                  <v-form
                                    ref="stepForm2"
                                    v-model="stepValid2"
                                    lazy-validation
                                  >
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        md="6"
                                      >
                                        <v-text-field
                                          v-model.trim="memberItem.nombre"
                                          outlined
                                          :rules="campoRules"
                                          :readonly="showOption"
                                          label="NOMBRE"
                                          placeholder="NOMBRE"
                                        />
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        md="6"
                                      >
                                        <v-text-field
                                          v-model.trim="memberItem.apellido"
                                          outlined
                                          :rules="campoRules"
                                          :readonly="showOption"
                                          label="APELLIDOS"
                                          placeholder="APELLIDOS"
                                        />
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        md="6"
                                      >
                                        <v-text-field
                                          v-model.trim="memberItem.mail"
                                          outlined
                                          :rules="emailRules"
                                          :readonly="showOption"
                                          label="EMAIL"
                                          placeholder="EMAIL"
                                          @keydown.space.prevent
                                        />
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        md="6"
                                      >
                                        <!-- <v-text-field
                                          v-model.trim="memberItem.telefono"
                                          outlined
                                          :rules="telefonoRules"
                                          :readonly="showOption"
                                          label="TELÉFONO"
                                          placeholder="TELÉFONO"
                                          @keydown.space.prevent
                                          @keypress="keyboardKeysAllowedForTelephone"
                                        /> -->
                                        <vue-tel-input-vuetify
                                          v-model.trim="memberItem.telefono"
                                          default-country="MX"
                                          :max-len="30"
                                          mode="international"
                                          :clearable="!showOption"
                                          outlined
                                          label="TELÉFONO"
                                          placeholder="TELÉFONO"
                                          :rules="telefonoRules"
                                          :readonly="showOption"
                                          :error-messages="telephoneErrorMessage"
                                          @keydown.space.prevent
                                          @keydown="keyboardKeysAllowedForTelephone"
                                          @input="isValidTelephone"
                                        />
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        md="6"
                                      >
                                        <v-text-field
                                          v-model.trim="memberItem.especialidad"
                                          outlined
                                          :readonly="showOption"
                                          label="GIRO O ESPECIALIDAD A REPRESENTAR EN BNI"
                                          placeholder="GIRO O ESPECIALIDAD A REPRESENTAR EN BNI"
                                        />
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        md="6"
                                      >
                                        <v-text-field
                                          v-model.trim="memberItem.invito"
                                          outlined
                                          :readonly="showOption"
                                          label="NOMBRE Y APELLIDO DE QUIEN TE INVITÓ"
                                          placeholder="NOMBRE Y APELLIDO DE QUIEN TE INVITÓ"
                                        />
                                      </v-col>
                                    </v-row>
                                  </v-form>
                                </v-col>
                              </v-row>
                            </v-card>

                            <v-btn
                              @click="close"
                            >
                              Cancelar
                            </v-btn>

                            <v-btn
                              @click="e1 = 1"
                            >
                              Atrás
                            </v-btn>

                            <v-btn
                              color="primary"
                              :disabled="!stepValid2"
                              @click="validate2"
                            >
                              Siguiente
                            </v-btn>
                          </v-stepper-content>

                          <v-stepper-content step="3">
                            <v-card
                              class="mb-12"
                              elevation="2"
                              color="grey lighten-4"
                            >
                              <v-row>
                                <v-col
                                  class="mx-auto"
                                  cols="11"
                                  md="11"
                                >
                                  <v-form
                                    ref="stepForm3"
                                    v-model="stepValid3"
                                    lazy-validation
                                  >
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        md="6"
                                      >
                                        <v-text-field
                                          v-model.trim="memberItem.razon"
                                          outlined
                                          :readonly="showOption"
                                          label="RAZON SOCIAL / NOMBRE FISCAL"
                                          placeholder="RAZON SOCIAL / NOMBRE FISCAL"
                                        />
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        md="6"
                                      >
                                        <v-text-field
                                          v-model.trim="memberItem.cif"
                                          :maxlength="20"
                                          outlined
                                          :readonly="showOption"
                                          label="RFC"
                                          placeholder="RFC"
                                          @keydown.space.prevent
                                        />
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        md="6"
                                      >
                                        <v-text-field
                                          v-model.trim="memberItem.domicilio"
                                          outlined
                                          :readonly="showOption"
                                          label="DIRECCIÓN FISCAL"
                                          placeholder="DIRECCIÓN FISCAL"
                                        />
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        md="6"
                                      >
                                        <v-text-field
                                          v-model.trim="memberItem.poblacion"
                                          outlined
                                          :readonly="showOption"
                                          label="CIUDAD"
                                          placeholder="CIUDAD"
                                        />
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        md="6"
                                      >
                                        <v-text-field
                                          v-model.trim="memberItem.provincia"
                                          outlined
                                          :readonly="showOption"
                                          label="ESTADO"
                                          placeholder="ESTADO"
                                        />
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        md="6"
                                      >
                                        <v-text-field
                                          v-model.trim="memberItem.postal"
                                          type="number"
                                          outlined
                                          :rules="postalRules"
                                          :readonly="showOption"
                                          label="CÓDIGO POSTAL"
                                          placeholder="CÓDIGO POSTAL"
                                          @keydown.space.prevent
                                        />
                                      </v-col>
                                    </v-row>
                                  </v-form>
                                </v-col>
                              </v-row>
                            </v-card>

                            <v-btn
                              @click="close"
                            >
                              Cancelar
                            </v-btn>

                            <v-btn
                              @click="e1 = 2"
                            >
                              Atrás
                            </v-btn>

                            <v-btn
                              color="primary"
                              :disabled="!stepValid3"
                              @click="validate3"
                            >
                              Siguiente
                            </v-btn>
                          </v-stepper-content>
                          <v-stepper-content step="4">
                            <v-card
                              class="mb-12"
                              elevation="2"
                              color="grey lighten-4"
                            >
                              <v-row>
                                <v-col
                                  class="mx-auto"
                                  cols="11"
                                  md="11"
                                >
                                  <v-form
                                    ref="stepForm4"
                                    v-model="stepValid4"
                                    lazy-validation
                                  >
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        md="6"
                                      >
                                        <v-select
                                          v-model="memberItem.miembroBNI"
                                          outlined
                                          :readonly="showOption"
                                          :items="grupo"
                                          item-text="text"
                                          item-value="value"
                                          label="¿Ha sido miembro de BNI anteriormente?"
                                          placeholder="¿Ha sido miembro de BNI anteriormente?"
                                          hint="¿Ha sido miembro de BNI anteriormente?"
                                        />
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        md="6"
                                      >
                                        <v-text-field
                                          v-model.trim="memberItem.grupoBNIAnt"
                                          outlined
                                          :readonly="showOption"
                                          label="En caso afirmativo, ¿cuándo y en qué grupo?"
                                          placeholder="En caso afirmativo, ¿cuándo y en qué grupo?"
                                          hint="En caso afirmativo, ¿cuándo y en qué grupo?"
                                        />
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        md="6"
                                      >
                                        <v-select
                                          v-model="memberItem.miembroOrgQ"
                                          outlined
                                          :readonly="showOption"
                                          :items="grupo"
                                          item-text="text"
                                          item-value="value"
                                          label="¿Es miembro actualmente de alguna otra organización de networking cuyo objetivo sea también el intercambio de referencias?"
                                          placeholder="¿Es miembro actualmente de alguna otra organización de networking cuyo objetivo sea también el intercambio de referencias?"
                                          hint="¿Es miembro actualmente de alguna otra organización de networking cuyo objetivo sea también el intercambio de referencias?"
                                        />
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        md="6"
                                      >
                                        <v-text-field
                                          v-model.trim="memberItem.miembroOrgA"
                                          outlined
                                          :readonly="showOption"
                                          label="En caso afirmativo, ¿a cuál?"
                                          placeholder="En caso afirmativo, ¿a cuál?"
                                          hint="En caso afirmativo, ¿a cuál?"
                                        />
                                      </v-col>
                                    </v-row>
                                  </v-form>
                                </v-col>
                              </v-row>
                            </v-card>

                            <v-btn
                              @click="close"
                            >
                              Cancelar
                            </v-btn>

                            <v-btn
                              @click="e1 = 3"
                            >
                              Atrás
                            </v-btn>

                            <v-btn
                              v-show="!showOption && isAdmin"
                              color="primary"
                              :disabled="!stepValid4"
                              @click="validate4"
                            >
                              Grabar
                            </v-btn>
                          </v-stepper-content>
                        </v-stepper-items>
                      </v-stepper>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>

            <!-- MODAL LISTADO DE SUSCRIPCIONES DE USUARIO -->
            <v-dialog
              v-model="dialogSuscripciones"
              max-width="95%"
            >
              <v-card>
                <v-data-table
                  :headers="headerSuscripciones"
                  :items="suscripciones"
                  :items-per-page="10"
                >
                  <template v-slot:item.valor="{ item }">
                    ${{ item.valor }}MXN
                  </template>
                  <template v-slot:item.lyra_metodo_pago="{ item }">
                    {{ item.lyra_metodo_pago | toPaymentMethod }}
                  </template>
                  <template v-slot:item.token="{ item }">
                    <a
                      :href="frontendUrl + 'member/resume/' + item.token"
                      target="_blank"
                    >
                      {{ frontendUrl }}member/resume/{{ item.token }}
                    </a>
                  </template>
                  <!-- OPCIÓN DESCARGA COMPROBANTE -->
                  <template v-slot:item.accionesSuscripciones="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-if="item.comprobante !== null"
                          small
                          class="mr-2"
                          color="orange darken-2"
                          v-bind="attrs"
                          v-on="on"
                          @click="downloadFile(item.comprobante, 'comprobante')"
                        >
                          mdi-download
                        </v-icon>
                      </template>
                      <span>Descargar comprobante</span>
                    </v-tooltip>
                  </template>
                  <!-- //OPCIÓN DESCARGA COMPROBANTE -->
                </v-data-table>
                <v-card-subtitle class="mt-5 text-h6">
                  <div>
                    &#185; Al pagar en cuotas se considera PAGADO, al momento de recibir la confirmación del pago de la primera cuota.
                  </div>
                  <div>
                    &#178; Solo los pagos realizados por Transferencia tienen un fichero en la columna, disponible para descarga.
                  </div>
                </v-card-subtitle>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeSuscripciones"
                  >
                    Cerrar
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- //MODAL LISTADO DE SUSCRIPCIONES DE USUARIO -->

            <!-- MODAL CONFIRMAR PAGO MANUAL -->
            <v-dialog
              v-model="dialogPagoManual"
              max-width="500px"
            >
              <v-card>
                <v-card-title
                  class="text-h5"
                >
                  ¿Está seguro que desea indicar que este usuario ha pagado?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeModalPagoManual"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="verificarAltaComprobanteDeRenovacion()"
                  >
                    Sí
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- //MODAL CONFIRMAR PAGO MANUAL -->

            <!-- MODAL CONFIRMAR GRUPO PARA PAGO MANUAL -->
            <v-dialog
              v-model="dialogGrupoPagoManual"
              max-width="500px"
            >
              <v-card>
                <v-card-title
                  class="text-h5"
                >
                  El usuario que realizó el pago que está a punto de confirmar, no cargó al sistema el comprobante de transferencia.
                  Por tanto, indique <br> a continuación el grupo al que correspondió el monto recibido.
                </v-card-title>
                <v-card-text>
                  <v-radio-group
                    v-model="grupoPagoManualSelected"
                    column
                  >
                    <v-radio
                      v-for="item of gruposPagoManual"
                      :key="item.id"
                      :value="item.id"
                    >
                      <template v-slot:label>
                        <div
                          class="black--text font-weight-regular"
                        >
                          {{ item.grupo }}
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                  <span
                    v-if="errorGrupoPagoManual"
                    class="red--text"
                  >
                    Debe seleccionar un grupo
                  </span>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeModalGrupoPagoManual"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="cambiarGrupoAntesDePagoManual()"
                  >
                    Continuar
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- //MODAL CONFIRMAR GRUPO PARA PAGO MANUAL -->

            <!-- MODAL ANULAR PAGO -->
            <v-dialog
              v-model="dialogAnularPago"
              max-width="500px"
            >
              <v-card>
                <v-card-title
                  class="text-h5"
                >
                  <p class="text-needs-wrapping">
                    ¿Está seguro que desea anular el pago de este usuario? <br><br>
                    El usuario podrá volver a realizarlo, utilizando el mismo link que recibió en su correo cuando generó la orden
                  </p>
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeModalAnularPago"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="anularPago()"
                  >
                    Sí
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- //MODAL ANULAR PAGO -->

            <!-- MODAL CONFIRMAR ALTA O BAJA USUARIO -->
            <v-dialog
              v-model="dialogRevoke"
              max-width="500px"
            >
              <v-card>
                <v-card-title
                  class="text-h5"
                >
                  Confirmar alta o baja de usuario
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeRevoke"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="revokeMemberConfirm"
                  >
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- //MODAL CONFIRMAR ALTA O BAJA USUARIO -->
          </v-toolbar>
        </template>
        <!-- OPCIÓN DESCARGA CONSTANCIA FISCAL -->
        <template v-slot:item.constanciaFiscal="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="item.constanciaFiscal !== null"
                small
                class="mr-2"
                color="orange darken-2"
                v-bind="attrs"
                v-on="on"
                @click="downloadFile(item.constanciaFiscal, 'constancia')"
              >
                mdi-download
              </v-icon>
            </template>
            <span>Descargar constancia</span>
          </v-tooltip>
        </template>
        <!-- //OPCIÓN DESCARGA CONSTANCIA FISCAL -->
        <template v-slot:item.estado="{ item }">
          <v-chip
            :color="getColor(item.estado)"
            dark
          >
            {{ item.estado }}
          </v-chip>
        </template>
        <template v-slot:item.acciones="{ item }">
          <!-- OPCIÓN VER -->
          <v-icon
            small
            class="mr-2"
            color="blue darken-3"
            @click="showItem(item)"
          >
            mdi-eye
          </v-icon>
          <!-- //OPCIÓN VER -->
          <!-- OPCIÓN EDITAR -->
          <v-icon
            v-show="isAdmin"
            small
            class="mr-2"
            color="teal darken-2"
            @click="editItem(item)"
          >
            mdi-pencil-outline
          </v-icon>
          <!-- //OPCIÓN EDITAR -->
          <!-- OPCIÓN LISTAR SUSCRIPCIONES DE USUARIO -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                color="blue darken-4"
                v-bind="attrs"
                v-on="on"
                @click="showSuscripciones(item)"
              >
                mdi-format-list-checkbox
              </v-icon>
            </template>
            <span>Ver suscripciones</span>
          </v-tooltip>
          <!-- //OPCIÓN LISTAR SUSCRIPCIONES DE USUARIO -->
          <!-- OPCIÓN REALIZAR PAGO MANUAL -->
          <v-tooltip
            v-if="isAdmin"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="item.estado_ult_suscripcion == 'PENDIENTE'"
                small
                class="mr-2"
                color="orange darken-2"
                v-bind="attrs"
                v-on="on"
                @click="showModalPagoManual(item)"
              >
                mdi-cash-multiple
              </v-icon>
            </template>
            <span>Confirmar último pago</span>
          </v-tooltip>
          <!-- //OPCIÓN REALIZAR PAGO MANUAL -->
          <!-- OPCIÓN ANULAR PAGO -->
          <v-tooltip
            v-if="isAdmin"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="item.estado_ult_suscripcion == 'PAGADO'"
                small
                class="mr-2"
                color="orange darken-2"
                v-bind="attrs"
                v-on="on"
                @click="showModalAnularPago(item)"
              >
                mdi-close-box-multiple-outline
              </v-icon>
            </template>
            <span>Anular último pago</span>
          </v-tooltip>
          <!-- //OPCIÓN ANULAR PAGO -->
          <!-- OPCIÓN ALTA O BAJA DE USUARIO -->
          <v-tooltip
            v-if="isAdmin"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="item.eliminado == 0"
                small
                class="mr-2"
                color="red darken-4"
                v-bind="attrs"
                v-on="on"
                @click="revokeItem(item)"
              >
                mdi-account-lock
              </v-icon>
            </template>
            <span>Dar de baja</span>
          </v-tooltip>
          <v-tooltip
            v-if="isAdmin"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="item.eliminado == 1"
                small
                class="mr-2"
                color="red darken-4"
                v-bind="attrs"
                v-on="on"
                @click="revokeItem(item)"
              >
                mdi-account-check
              </v-icon>
            </template>
            <span>Dar de alta</span>
          </v-tooltip>
          <!-- //OPCIÓN ALTA O BAJA DE USUARIO -->
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initialize"
          >
            Recargar
          </v-btn>
        </template>
      </v-data-table>
      <v-row
        align="center"
        class="list px-3 mx-auto"
      >
        <v-col
          cols="12"
          sm="12"
        >
          <v-row>
            <v-col
              cols="12"
              sm="10"
            >
              <v-pagination
                v-model="page"
                :length="totalPages"
                total-visible="7"
                next-icon="mdi-menu-right"
                prev-icon="mdi-menu-left"
                @input="handlePageChange"
              />
            </v-col>

            <v-col
              cols="4"
              sm="2"
            >
              <v-select
                v-model="itemsPerPage"
                :items="itemsPerPageArray"
                label="Registros por página"
                @change="handleitemsPerPageChange"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>
  import GruposApi from '@/services/api/Grupos'
  import MembersApi from '@/services/api/Miembros'
  import SuscripcionesApi from '@/services/api/Suscripciones'

  export default {
    name: 'Miembros',

    components: {
    },

    filters: {
      toPaymentMethod (value) {
        if (undefined !== value) {
          if (value === 'SDD') {
            return 'Débito SEPA'
          }
          if (value === 'PAYPAL') {
            return 'PayPal'
          }
          if (value === 'Pago en cuotas') {
            return 'Pago en cuotas'
          }
          if (value === 'IP_WIRE') {
            return 'Transferencia'
          }
          if (value === null) {
            return ''
          }
          /**
           * sirve para los nombres propios de las tarjetas
           */
          return value
        }
      },
    },

    data () {
      return {
        isAdmin: false,
        search: '',
        progressBar: false,
        valid: true,
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        dialog: false,
        frontendUrl: null,
        miembros: [],
        editedIndex: -1,
        lazy: false,
        dialogRevoke: false,
        dialogSuscripciones: false,
        dialogPagoManual: false,
        dialogGrupoPagoManual: false,
        dialogAnularPago: false,
        showOption: false,
        headers: [
          {
            text: 'Nombre',
            align: 'center',
            sortable: true,
            value: 'nombre',
            filterable: true,
          },
          {
            text: 'Apellido',
            align: 'center',
            sortable: true,
            value: 'apellido',
          },
          {
            text: 'E-mail',
            align: 'center',
            sortable: true,
            value: 'mail',
          },
          {
            text: 'Grupo',
            align: 'center',
            sortable: true,
            value: 'grupo.grupo',
          },
          {
            text: 'Fecha de registro',
            align: 'center',
            sortable: true,
            value: 'fechaRegistro',
            sort: (a, b) => {
              a = a.split('-')
              a = new Date(a[2], a[1] - 1, a[0])
              b = b.split('-')
              b = new Date(b[2], b[1] - 1, b[0])
              return b - a
            },
          },
          {
            text: 'Últ. modificación',
            align: 'center',
            sortable: false,
            value: 'updated_at',
            sort: (a, b) => {
              a = a.split('-')
              a = new Date(a[2], a[1] - 1, a[0])
              b = b.split('-')
              b = new Date(b[2], b[1] - 1, b[0])
              return b - a
            },
          },
          {
            text: 'Const. fiscal',
            align: 'center',
            sortable: false,
            value: 'constanciaFiscal',
          },
          {
            text: 'Estado',
            align: 'center',
            sortable: true,
            value: 'estado',
          },
          {
            text: 'Acciones',
            align: 'center',
            value: 'acciones',
            sortable: false,
          },
        ],
        e1: 1,
        stepValid1: true,
        stepValid2: true,
        stepValid3: true,
        stepValid4: true,
        memberItem: {
          grupo: '',
          nombre: '',
          apellido: '',
          mail: '',
          token: '',
          telefono: null,
          especialidad: '',
          invito: '',
          razon: '',
          cif: '',
          domicilio: '',
          poblacion: '',
          provincia: '',
          postal: null,
          miembroBNI: '',
          grupoBNIAnt: '',
          miembroOrgQ: '',
          miembroOrgA: '',
          esInvitacion: true,
          estado: '',
          estado_ult_suscripcion: null,
        },
        defaultItem: {
          grupo: '',
          nombre: '',
          apellido: '',
          mail: '',
          telefono: null,
          especialidad: '',
          invito: '',
          razon: '',
          cif: '',
          domicilio: '',
          poblacion: '',
          provincia: '',
          postal: null,
          miembroBNI: '',
          grupoBNIAnt: '',
          miembroOrgQ: '',
          miembroOrgA: '',
          esInvitacion: true,
          estado: '',
          estado_ult_suscripcion: null,
        },
        grupo: [
          { value: 'SI', text: 'SI' },
          { value: 'NO', text: 'NO' },
        ],
        grupoBNI: [],
        emailRules: [
          v => !!v || 'Completa este campo, por favor',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo electrónico correcto',
        ],
        telefonoRules: [
          v => !!v || 'Completa este campo, por favor',
          v => /^.{7,30}$/.test(v) || 'Ingrese un número telefónico correcto',
        ],
        postalRules: [
          v => !v || /^[0-9]{4,5}$/.test(v) || 'Ingrese un código postal correcto',
        ],
        campoRules: [
          v => !!v || 'Completa este campo, por favor',
        ],
        telephoneErrorMessage: null,
        headerSuscripciones: [
          {
            text: 'Grupo',
            align: 'center',
            sortable: true,
            value: 'grupo.grupo',
          },
          {
            text: 'Estado',
            align: 'center',
            sortable: true,
            value: 'estado',
          },
          {
            text: 'Monto',
            align: 'center',
            sortable: true,
            value: 'valor',
          },
          {
            text: 'Forma de pago¹',
            align: 'center',
            value: 'lyra_metodo_pago',
          },
          {
            text: 'Cuotas pagadas',
            align: 'center',
            sortable: false,
            value: 'num_cuotas',
          },
          {
            text: 'Total abonado en cuotas',
            align: 'center',
            sortable: false,
            value: 'total_abonado_en_cuotas',
          },
          {
            text: 'Fecha de pago',
            align: 'center',
            sortable: true,
            value: 'lyra_presentation_date',
          },
          {
            text: 'Identificador en plataforma externa',
            align: 'center',
            value: 'lyra_trans_uuid',
          },
          {
            text: 'URL de pago',
            align: 'center',
            value: 'token',
          },
          {
            text: 'Comprobante transf.²',
            align: 'center',
            value: 'accionesSuscripciones',
            sortable: false,
          },
        ],
        suscripciones: [],
        gruposPagoManual: null,
        grupoPagoManualSelected: null,
        errorGrupoPagoManual: false,
        // Paginacion
        page: 1,
        totalPages: 0,
        itemsPerPage: 20,
        itemsPerPageArray: [20, 50, 100],
      }
    },

    computed: {
      formTitle () {
        if (this.editedIndex !== -1 && this.showOption === true) {
          return 'Datos del miembro'
        } else if (this.editedIndex !== -1 && this.showOption === false) {
          return 'Modificar miembro'
        } else {
          return 'Invitar miembro'
        }
        // return this.editedIndex === -1 ? 'Invitar miembro' : 'Modificar miembro'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogRevoke (val) {
        val || this.closeRevoke()
      },
      dialogSuscripciones (val) {
        val || this.closeSuscripciones()
      },
      dialogAnularPago (val) {
        val || this.closeModalAnularPago()
      },
    },

    created () {
      this.getSessionRole()
      this.initialize()
    },

    methods: {
      getSessionRole () {
        const role = localStorage.getItem('role')

        if (role === 'ROLE_ADMIN') {
          this.isAdmin = true
        }
      },

      initialize () {
        this.frontendUrl = process.env.VUE_APP_FRONTEND_URL
        this.fetchMiembros()
        this.fetchGrupos()
      },

      fetchMiembros () {
        this.progressBar = true

        const params = this.getRequestParams(
          this.search,
          this.page,
          this.itemsPerPage,
        )

        MembersApi.getAllMiembros(params)
          .then(response => {
            this.progressBar = false
            this.e1 = 1 // resetear el formulario en pasos de miembros
            this.editedIndex = -1 // resetear el último index seleccionado
            this.miembros = response.items // resetear array de miembros
            this.totalPages = response.pages
          })
          .catch(error => {
            this.progressBar = false
            console.log(error)
          })
      },

      fetchGrupos () {
        GruposApi.getGruposActivosNoRenovaciones()
          .then(grupo => {
            this.grupoBNI = grupo
          })
          .catch(error => console.log(error))
      },

      getRequestParams (search, page, itemsPerPage) {
        const params = {}

        if (search) {
          params.search = search
        }

        if (page) {
          params.page = page
        }

        if (itemsPerPage) {
          params.itemsPerPage = itemsPerPage
        }

        return params
      },

      handlePageChange (value) {
        this.page = value
        this.initialize()
      },

      handleitemsPerPageChange (size) {
        this.itemsPerPage = size
        this.page = 1
        this.initialize()
      },

      isValidTelephone (value, detail) {
        // console.log(detail.isValid)
        if (detail.isValid) {
          this.telephoneErrorMessage = null
        } else {
          this.telephoneErrorMessage = 'El formato no coincide con el país seleccionado'
        }
      },

      keyboardKeysAllowedForTelephone ($event) {
        // Basado en: Key values for keyboard events
        // console.log($event.key)
        const charsAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '+', 'Backspace', 'ArrowRight', 'ArrowLeft', 'Delete']
        if (!charsAllowed.includes($event.key)) {
          $event.preventDefault()
        }
      },

      showItem (item) {
        this.showOption = true
        this.editedIndex = this.miembros.indexOf(item)
        this.memberItem = Object.assign({}, item)
        this.dialog = true
      },

      editItem (item) {
        this.editedIndex = this.miembros.indexOf(item)
        this.memberItem = Object.assign({}, item)
        this.dialog = true
      },

      revokeItem (item) {
        this.editedIndex = this.miembros.indexOf(item)
        this.memberItem = Object.assign({}, item)
        this.dialogRevoke = true
      },

      showModalPagoManual (item) {
        this.editedIndex = this.miembros.indexOf(item)
        this.memberItem = Object.assign({}, item)
        this.dialogPagoManual = true
      },

      showModalAnularPago (item) {
        this.editedIndex = this.miembros.indexOf(item)
        this.memberItem = Object.assign({}, item)
        this.dialogAnularPago = true
      },

      revokeMemberConfirm () {
        this.closeRevoke()
        this.progressBar = true
        MembersApi.revoke(this.memberItem.id)
          .then(response => {
            this.progressBar = false
            this.text = 'Registro modificado correctamente. Se recargará la página...'
            this.snackbar = true
            this.colorSnak = 'success'
            this.editedIndex = -1
            setTimeout(() => { location.reload() }, 2000)
          })
          .catch(error => {
            console.log(error)
            this.progressBar = false
            this.text = 'El registro no puede ser modificado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
            this.editedIndex = -1
          })
      },

      verificarAltaComprobanteDeRenovacion () {
        this.progressBar = true

        /*
          * Si es renovación, y no se cargó un comprobante de transferencia,
          * se necesita confirmar el grupo al que el usuario pagó la suscripción
        */
        SuscripcionesApi.verificarAltaComprobanteDeRenovacion(this.memberItem.id)
          .then(response => {
            if (response.status === 200) {
              this.realizarPagoManual()
              this.closeModalPagoManual()
            } else {
              GruposApi.getGrupoPadreConRenovaciones(this.memberItem.grupo.id)
                .then(response => {
                  this.gruposPagoManual = response
                  this.progressBar = false
                  this.dialogPagoManual = false
                  this.dialogGrupoPagoManual = true
                })
                .catch(error => {
                  console.log(error)
                  this.progressBar = false
                  this.text = 'El registro no puede ser modificado en este momento'
                  this.colorSnak = 'error'
                  this.snackbar = true
                  this.closeModalPagoManual()
                })
            }
          })
          .catch(error => {
            console.log(error)
            this.progressBar = false
            this.text = 'El registro no puede ser modificado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
            this.closeModalPagoManual()
          })
      },

      cambiarGrupoAntesDePagoManual () {
        if (this.grupoPagoManualSelected === null) {
          this.errorGrupoPagoManual = true
        } else {
          this.errorGrupoPagoManual = false
          this.progressBar = true

          const data = {
            miembro_id: this.memberItem.id,
            grupo_id: this.grupoPagoManualSelected,
          }

          SuscripcionesApi.cambiarGrupoDeSuscripcion(data)
            .then(response => {
              this.dialogGrupoPagoManual = false
              this.realizarPagoManual()
            })
            .catch(error => {
              console.log(error)
              this.progressBar = false
              this.text = 'El registro no puede ser modificado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
              this.editedIndex = -1
            })
        }
      },

      realizarPagoManual () {
        SuscripcionesApi.pagoManual(this.memberItem.id)
          .then(response => {
            this.progressBar = false
            this.text = 'Registro modificado correctamente. Se recargará la página...'
            this.snackbar = true
            this.colorSnak = 'success'
            this.editedIndex = -1
            setTimeout(() => { location.reload() }, 2000)
          })
          .catch(error => {
            console.log(error)
            this.progressBar = false
            this.text = 'El registro no puede ser modificado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
            this.editedIndex = -1
          })
      },

      anularPago () {
        this.closeModalAnularPago()
        this.progressBar = true
        SuscripcionesApi.anularPago(this.memberItem.id)
          .then(response => {
            this.progressBar = false
            this.text = 'Registro modificado correctamente. Se recargará la página...'
            this.snackbar = true
            this.colorSnak = 'success'
            this.editedIndex = -1
            setTimeout(() => { location.reload() }, 2000)
          })
          .catch(error => {
            console.log(error)
            this.progressBar = false
            this.text = 'El registro no puede ser modificado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
            this.editedIndex = -1
          })
      },

      close () {
        this.showOption = false
        this.dialog = false
        setTimeout(() => {
          this.memberItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.e1 = 1
        }, 300)
      },

      closeRevoke () {
        this.dialogRevoke = false
        this.$nextTick(() => {
          this.memberItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeModalPagoManual () {
        this.dialogPagoManual = false
        this.$nextTick(() => {
          this.memberItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeModalGrupoPagoManual () {
        this.dialogGrupoPagoManual = false
        this.$nextTick(() => {
          this.memberItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeModalAnularPago () {
        this.dialogAnularPago = false
        this.$nextTick(() => {
          this.memberItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        this.progressBar = true
        if (this.editedIndex > -1) {
          this.memberItem.esInvitacion = true
          MembersApi.update(this.memberItem.id, this.memberItem)
            .then(response => {
              this.progressBar = false
              this.text = 'Registro modificado correctamente'
              this.snackbar = true
              this.colorSnak = 'success'
              this.initialize()
            })
            .catch(error => {
              console.log(error)
              this.progressBar = false
              this.text = 'El registro no puede ser modificado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
              this.initialize()
            })
        } else {
          this.memberItem.estado = 'INVITADO'
          MembersApi.add(this.memberItem)
            .then(response => {
              this.text = 'Registro creado correctamente'
              this.colorSnak = 'success'
              this.snackbar = true
              this.progressBar = false
              this.initialize()
            })
            .catch(error => {
              console.log(error)
              this.text = 'El registro no puede ser creado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
              this.progressBar = false
              this.initialize()
            })
        }
        this.close()
      },

      getColor (estado) {
        if (estado === 'SUSCRITO') return 'green'
        else if (estado === 'EN PROCESO DE PAGO') return 'red'
        else if (estado === 'REGISTRADO') return 'orange'
        else if (estado === 'INVITADO') return 'light-blue'
        else return 'black'
      },

      validate1 () {
        this.stepValid1 = false
        const v = this.$refs.stepForm1.validate()
        if (v) {
          this.stepValid1 = true
          this.e1 = 2
        }
      },
      validate2 () {
        this.stepValid2 = false
        const v = this.$refs.stepForm2.validate()
        if (v) {
          this.stepValid2 = true
          this.e1 = 3
        }
      },
      validate3 () {
        this.stepValid3 = false
        const v = this.$refs.stepForm3.validate()
        if (v) {
          this.stepValid3 = true
          this.e1 = 4
        }
      },
      validate4 () {
        this.stepValid4 = false
        const v = this.$refs.stepForm4.validate()
        if (v) {
          this.stepValid4 = true
          this.save()
        }
      },

      showSuscripciones (item) {
        this.dialogSuscripciones = true
        SuscripcionesApi.getSuscripcionesMiembro(item.id)
          .then(suscripciones => {
            this.suscripciones = suscripciones
          })
          .catch(error => console.log(error))
      },

      closeSuscripciones () {
        this.suscripciones = []
        this.dialogSuscripciones = false
      },

      downloadFile (name, type) {
        const nombre = name
        let entity = []

        if (type === 'constancia') {
          entity = MembersApi
        } else {
          entity = SuscripcionesApi
        }

        entity.downloadFile(name)
          .then(file => {
            this.forceFileDownload(file, nombre)
          })
          .catch(error => console.log(error))
      },

      forceFileDownload (response, nombre) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nombre)
        document.body.appendChild(link)
        link.click()
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .text-needs-wrapping {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
</style>
